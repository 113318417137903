

export const processAction = async ( action, navigate ) => {
    if ( action === '2023-login-form') {
        let resultAction = await fetch(`/api/redirectLogin`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
        let {message, redirectUrl} = await resultAction.json();
        if (resultAction.status === 403) {
            return {message, processed: true};
        } else {
            window.location = redirectUrl;
            return {processed: true};
        }
    } else if ( action === '2023-meeting-pdf' ) {

        let downloadUrl = `/api/generate2023PDF`;
        let resultAction = await fetch(downloadUrl, {
            method: 'POST',
            mode: 'cors',
            cache: 'default',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        const base64ToBlob = b => {
            const binaryString = atob(b.split(',')[1]); // Binary data string
            return new Blob([binaryString], { type: 'image/png' }); // Create a BLOB object
        };
        let result = await resultAction.text();
        console.log('result', result);
        const blob = result;//base64ToBlob(result);  /// just some function to make a blob out of base64 data
        // const url = URL.createObjectURL(blob);
        // window.location.href = url; // and here, we finally forward the data to the new window
        // window.focus();
        window.open(downloadUrl, '_blank');

        return {
            processed: true,
            downloadUrl,
        };
    } else {
        return {
            processed: false,
        }
    }
}