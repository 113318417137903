import {faLock, faMicrophone, faShareSquare, faSpinner} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf as farFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faCheckSquare as farCheckSquare } from "@fortawesome/free-regular-svg-icons";

import React, {Fragment, useEffect, useState} from "react";
import styled from 'styled-components';
import {useLocation, useNavigate} from "react-router-dom";
import {blue, red} from "../../styles";

const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const TopBanner = styled.div`
  background-color: #f7f7f7;
  padding: 15px 0px;
`;

const BannerWapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const H1 = styled.h1`
  color: #00A88E;
  font-size: 23px;
  line-height: 38px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
`;

const H2 = styled.h2`
  margin-top: 30px;
  border-bottom: solid 2px #0083CA;
  padding-bottom: 7px;
  color: #00A88E;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;

`;

const StandardH3 = styled.h3`
  margin-top: 35px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  font-weight: 500;
`;

const StandardH4 = styled.h4`
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  margin: ${props => props.first ? '10px 0px 15px':'25px 0px 15px'} ;
  color: #333333;
`;

const ParagraphText = styled.p`
  text-align: start;
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  color: #3A9EB5;
  margin-right: 5px;
`;

const LinkInText = styled.a`
  color: #4a4a4a;
  font-weight: bold;
  :hover {
    color: #0083ca;
  }
`;

const InlineIcon = styled(FontAwesomeIcon)`
  margin-left: 6px;
`;

const UlStyled = styled.ul`
  font-size: 14px;
  line-height: 1.7;
  font-family: 'Open Sans' sans-serif;
  list-style-type: none;
  text-align: left;
  padding-left: 0px;
`;

const TopLevelLi = styled.li`
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
`;

const NoBullets = styled.ul`
  list-style-type: none;
`;

const ImagesIframe = () => {
  return (
    <iframe title="Actox posters 2023" src="https://www.actox.org/am/am2023/_includes/posters.asp" height="400" width="100%"></iframe>
  );
};


const courses = [
  {
    title: 'CE 1: Preclinical Considerations for Gene Therapies',
    coursepdf: '/books/CE1-Gene_Therapy_Coursebook.pdf',
    code: 'CE 1',
    certificate: '',
    survey: ''
  },
  {
    title: 'CE 2: Demystifying the Realm of Pink and Blue: Toxicologic Histopathology 101',
    code: 'CE 2',
    coursepdf: '/books/CE2-Histopathology_101.pdf',
    certificate: '',
    survey: ''
  },
  {
    title: 'CE 3: Fundamentals of Absorption, Distribution, Metabolism, and Excretion (ADME), Pharmacokinetics and Toxicokinetics for Toxicologists',
    code: 'CE 3',
    coursepdf: '/books/CE3-ADME_Coursebook.pdf',
    certificate: '',
    survey: ''
  },
  {
    title: 'CE 4: Juvenile Toxicology Three Years After ICH S11: Where Are We Now?',
    code: 'CE 4',
    coursepdf: '/books/CE4-Juvenile_Toxicology_Coursebook.pdf',
    certificate: '',
    survey: ''
  },
  {
    title: 'CE 5: Basic Considerations for Cell Therapy Toxicology from an Industry and Regulatory Perspective',
    code: 'CE 5',
    coursepdf: '/books/CE5-Cell_Therapy_Coursebook.pdf',
    certificate: '',
    survey: ''
  },
  {
    title: 'CE 6: From Anticipated Immunomodulation to Safety Concern: Use of Immune Biomarkers in Preclinical Studies',
    code: 'CE 6',
    coursepdf: '/books/CE6-Biomarkers_Coursebook.pdf',
    certificate: '',
    survey: ''
  },
  {
    title: 'CE 7: Medical Device Biocompatibility and Toxicological Risk Assessments: Processes, Challenges, and Solutions',
    coursepdf: '/books/CE7-Medical_Devices_Coursebook.pdf',
    code: 'CE 7',
    certificate: '',
    survey: ''
  },
  {

    title: 'CE 8: Drug Development in the 21st Century: Improving Safety Evaluation and Risk Characterization with <i>In Vitro</i> Systems',
    coursepdf: '/books/CE8-Drug_Development_Coursebook.pdf',
    code: 'CE 8',
    certificate: '',
    survey: ''
  }
]

const Materials2 = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [ces, setCes] = useState([]);
  const {
    pathname,
    search,
  } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch(`/api/is2023Member`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if ( result.status === 401 ) {
          navigate('/login?redirect=' + encodeURIComponent(pathname));
        } else if ( result.status === 403 ) {
          setError("You do not have access to this page");
        } else if ( result.status === 200 ) {
          await result.json();
          setError(null);
        } else {
          setError("An unexpected error occurred");
        }
      } catch (e) {
        setError("An unexpected error occurred");
      }
      setLoading(false);
    })();
  },[])
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const result = await fetch(`/api/getCEs2023`, {
  //         method: 'GET',
  //         mode: 'cors',
  //         cache: 'no-cache',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //       });
  //       if ( result.status === 401 ) {
  //         navigate('/login?redirect=' + encodeURIComponent(pathname));
  //       } else if ( result.status === 403 ) {
  //         setError("You do not have access to this page");
  //       } else if ( result.status === 200 ) {
  //         const { ces } = await result.json();
  //         setError(null);
  //         setCes( ces );
  //       } else {
  //         setError("An unexpected error occurred");
  //       }
  //     } catch (e) {
  //       setError("An unexpected error occurred");
  //     }
  //     setLoading(false);
  //   })();
  // },[])

  return (
    <div>
      <div className="container">
        {
          loading ? (
              <Panel>
                <Loading>
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                </Loading>
              </Panel>
          ) : error ? (
              <Panel>
                <div>
                  <br/>
                  <br/>
                  <p>{error}</p>
                  <br/>
                  <br/>
                </div>
              </Panel>
          ) : (
              <Fragment>

                    <div className="row">
                      <LeftColumn className="col-sm-9">
                        <img alt="people doing business"
                          src="https://www.actox.org/am/am2023/images/banners/Meeting-Materials.jpg" className="img-fluid" />
                        <div>
                          <H2>2023 Meeting Materials and Resources</H2>
                        </div>
                        <StandardH3>
                          <HeaderIcon icon={farFilePdf} />
                          <span>Meeting Publications</span>
                        </StandardH3>
                        <p>
                          <LinkInText href="/books/ACT-AM23-Abstract-Book.pdf" target="_blank">Abstract Book</LinkInText>
                          <InlineIcon icon={farFilePdf} />
                        </p>
                        <p>
                          <LinkInText href="https://members.actox.org/api/generate2023PDF" target="_blank">Attendee List</LinkInText>
                          <InlineIcon icon={faLock} />
                        </p>
                       
                        <p>
                          <LinkInText href="/books/ACT-AM23-pocketbook.pdf" target="_blank">Pocket Program</LinkInText>
                          <InlineIcon icon={farFilePdf} />
                        </p>
                        <StandardH3>
                          <HeaderIcon icon={faMicrophone} />
                          <span>Plenary Speakers</span>
                        </StandardH3>
                        <StandardH4 first>
                          <em>Monday, November 13, 2023</em>
                        </StandardH4>
                        <ParagraphText>
                          <strong>Plenary 1: Gain of Function and Pandemic Therapies: A Double-Edged Sword</strong>
                          <br />
                          <br />
                          <strong>Plenary Speaker: </strong><LinkInText href="https://www.actox.org/am/am2023/plenary.asp" target="_blank">
                            Michael V. Callahan, MD, DTM&H, MSPH
                          </LinkInText>
                        </ParagraphText>
                        <StandardH4>
                          <em>Wednesday, November 15, 2023</em>
                        </StandardH4>
                        <ParagraphText>
                          <strong>Plenary 2: Can Phage Therapy Safely Address the Looming Antibiotic Resistance Crisis?</strong>
                          <br />
                          <br />
                          <strong>Plenary Speaker: </strong><LinkInText href="https://www.actox.org/am/am2023/plenary.asp" target="_blank">
                            Paul E. Turner, PhD
                          </LinkInText>
                        </ParagraphText>

                        <StandardH3>
                          <HeaderIcon icon={farCheckSquare} />
                          <span>ePosters</span>
                        </StandardH3>
                        <ImagesIframe />

                        <StandardH3>
                          <HeaderIcon icon={farCheckSquare} />
                          <span>Post Annual Meeting Survey</span>
                        </StandardH3>
                        <ParagraphText>A link to the online post-meeting survey will be emailed to all attendees shortly after the meeting. We hope you will provide feedback, so we are able to evaluate and accommodate your suggestions at future ACT meetings.</ParagraphText>
                        <ParagraphText>
                          <LinkInText href="https://www.surveymonkey.com/r/actam2023" target="_blank">Post Annual Meeting Survey</LinkInText>
                        </ParagraphText>
                        <StandardH3>
                          <HeaderIcon icon={faShareSquare} />
                          <span> 2024 Session Proposals</span>
                        </StandardH3>
                        <ParagraphText>
                          Proposals for the 2024 meeting may be submitted using the&nbsp;
                          <LinkInText href="https://www.abstractscorecard.com/cfp/submit/login.asp?EventKey=CDVZZTNM" target="_blank">online Session Proposals System</LinkInText>
                          . <strong>The deadline is December 15, 2023.</strong> Please note that at least one chair of a session must be member of ACT. Questions may be directed to&nbsp;
                          <LinkInText href="mailto:jehler@actox.org">Jordan Ballance Ehler</LinkInText>.
                        </ParagraphText>
                        <ParagraphText>
                          <LinkInText href="https://www.abstractscorecard.com/cfp/submit/login.asp?EventKey=CDVZZTNM" target="_blank">2024 Online Session Proposal Submission System</LinkInText>
                        </ParagraphText>
                        <ParagraphText>The 45th Annual Meeting of the American College of Toxicology will be held November 17–20, 2024 at the JW Marriott Austin, Austin, Texas.</ParagraphText>
                      </LeftColumn>
                    </div>
              </Fragment>
          )
        }
      </div>
    </div>
  )
}

export default Materials2;