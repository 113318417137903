import styled from 'styled-components';
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { coursePath } from "../constants";

const PageTitle = styled.h1`
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  line-height: 52px;
  margin: 0 0 10px;
  position: relative;
  :before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 3px;
    background: #84ba3f;
    content: "";
  }
`;
const Dot = styled.span`
  background-color: #ffffff;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  position: relative;
  margin: 2px 8px;
`
const StyledNav = styled.nav`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  background-image: url("/images/About-banner.jpg");
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  height: 270px;
  padding: 120px 0 50px;
  position: relative;
  &:before {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
`;
const Container = styled.div`
  padding: 0px 15px;
  max-width: 1170px;
  text-align: left;
`;
const Items = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;  
  list-style-type: decimal;
  padding: 0px;
  color: white;
  max-width: 1170px;
  height: 52px;
`
const Item = styled.span`
  color: #363636;    
  padding: 0px 0;
  list-style: none;
  display: inline-block;
  font-size: 14px;
`
const ActiveItem = styled.span`
  color: #363636;    
  padding: 0px 0;
  list-style: none;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
`

const TopBanner = styled.div`
  background-color: #f7f7f7;
  padding: 15px 0px;
`;

const BannerWapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1170px;
  
`;

const H1 = styled.h1`
  color: #00A88E;
  font-size: 23px;
  line-height: 38px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
`;
const LinkInText = styled.a`
  color: #4a4a4a;
  font-weight: bold;
  text-decoration: none;
  :hover {
    color: #0083ca;
  }
`;

function BreadCrumb({ linkPrefix }) {
    const [showLoginMenu, setShowLoginMenu] = useState(false);
    const [showToXChangeMenu, setShowToXChangeMenu] = useState(false);
    const {
        pathname,
        search,
    } = useLocation();
    const isLoginPage = pathname && pathname.startsWith('/login') || false;

    const closeAllMenus = () => {
        setShowToXChangeMenu(false);
        setShowLoginMenu(false);
    }
    const onClickLoginMenu = () => {
        setTimeout(() => {
            setShowLoginMenu(!showLoginMenu);
        }, 0);
    };
    const onClickToXChangeMenu = () => {
        setTimeout(() => {
            setShowToXChangeMenu(!showToXChangeMenu);
        }, 0);
    };

    useEffect(() => {
        const onWindowClick = () => {
            closeAllMenus();
        }
        window.addEventListener("click", onWindowClick);
        return () => {
            window.removeEventListener("click", onWindowClick);
        };
    }, []);

    let pageTitle = 'American College of Toxicology';
    let meta = null;
    let title;
    let folderTitles = [];
    let banner = true;
    let customItem = null;
    let leftTitle = null;
    if (isLoginPage) {
        title = 'Login';
    } else if (pathname.indexOf('/admin') >= 0) {
        title = 'Admin';
    } else if (pathname.indexOf('/materials') >= 0) {
        title = 'Meeting Materials';
        leftTitle = 'Meeting Materials';
        banner = false;
        customItem = (<LinkInText href="#">44th Annual Meeting: November 12–15, 2023</LinkInText>);
    } else {
        title = 'Home'
    }

    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                {meta}
            </Helmet>
            {banner ? (
                <StyledNav>
                    <div className="main-content">
                        <Container className="container">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <PageTitle>{title}</PageTitle>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </StyledNav>
            ) : null}

            <TopBanner style={{background: '#f7f7f7', marginBottom: '60px'}}>
                <BannerWapper className='container'>

                    <div>
                        <H1>{leftTitle ? leftTitle : null}</H1>
                    </div>
                    <div>
                        {
                            customItem ? customItem : (
                                <Items>
                                    <Item>Home</Item>
                                    {
                                        folderTitles.map(folderTitle => (
                                            <React.Fragment key={folderTitle}>
                                                <Dot />
                                                <ActiveItem>{folderTitle}</ActiveItem>
                                            </React.Fragment>
                                        ))
                                    }
                                    <Dot />
                                    <ActiveItem>{title}</ActiveItem>
                                </Items>
                            )
                        }
                    </div>


                </BannerWapper>
            </TopBanner>
        </div>
    );
}

export default BreadCrumb;
