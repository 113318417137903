import styled, {keyframes} from 'styled-components';
import React, {useState, useEffect, useCallback, useRef} from "react";
import {useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faArrows,
    faBars, faCaretDown,
    faChevronDown,
    faChevronUp,
    faClose,
    faLock
} from "@fortawesome/free-solid-svg-icons";
import {blue, darkBlue, grayBoxes, orange, standardH3, standardP} from "../styles";
import debounce from 'lodash.debounce'
import Modal from "react-modal";

const menuItemTransition = keyframes`
    0% {
      background-color: white;
      color: ${standardH3};
    }
    100% {
      background-color: ${orange};
      color: white;
    }
`
const menuPanelTransition = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const StyledNav = styled.nav`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    padding: 0 8px;
    width: 100%;
  }
`;
const Container = styled.div`
  padding: 5px;
  max-width: 1170px;
  display: none;
  flex-direction: row;
  justify-content: center;
  @media (min-width: 992px) {
    display: flex;
  }
`;
const Item = styled.li`
  display: inline-block;
  padding: 0px;
`
const ItemButton = styled.button`
  padding: 8px 15px;
  text-transform: uppercase;
  border: 0px solid transparent;
  font-size: 14px;
  font-weight: 500;
  color: ${({hovered}) => hovered ? 'white' : '#363636'};
  background-color: ${({hovered}) => hovered ? orange : 'transparent'};
  animation-name: ${({hovered}) => hovered ? menuItemTransition : 'none'};
  animation-duration: 0.5s;
  display: inline-block;
  white-space: nowrap;
`;
const PanelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
  padding: 0;
  margin: 0;
  border: 0px solid transparent;
`;
const Panel = styled.div`
  position: absolute;
  z-index: 100;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1120px;
  opacity: ${({displayPanel}) => displayPanel ? '1' : '0'};
  animation-name: ${({displayPanel}) => displayPanel ? menuPanelTransition : 'none'};
  animation-duration: 0.3s;
`;
const InnerPanel = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
`;
const PanelSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: ${({first}) => first ? '0' : '32px'};
`;
const SectionHeader = styled.h4`
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: ${standardH3};
  margin-top: 23px;
  @media (min-width: 992px) {
    margin-top: ${({first}) => first ? '0' : '23px'};
  }
`;
const SectionImage = styled.img`
  margin-bottom: 15px;
`;
const SectionImageLink = styled.a`
  margin: 0;
`;
const SectionText = styled.p`
  margin-bottom: 15px;
  font-size: 14px;
  text-align: left;
`;
const SectionButton = styled.a`
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: ${({actionButton}) => actionButton ? orange : darkBlue};
  border: 1px solid ${({actionButton}) => actionButton ? orange : darkBlue};
  border-radius: 5px;
  color: white;
  font-size: 13px;
  text-decoration: none;
  padding: 6px 16px;
  line-height: 1.38;
  cursor: pointer;
  align-self: flex-start;
`;
const SectionLink = styled.a`
  border: 0 none;
  margin: 0;
  padding: 10px 0 10px 20px;
  position: relative;
  width: 100%;
  clear: both;
  
  color: #707070;
  font-size: 12px;
  text-align: left;
  text-decoration: none;

  -webkit-transition: padding 0.2s linear;
  -o-transition: padding 0.2s linear;
  transition: padding 0.2s linear;
  
  &:hover {
    color: #111;
    padding-left: 25px;
    text-decoration: underline;
  }
`;
const ArrowsWrapper = styled.span`
  position: absolute;
  left: 0;
  margin: auto 0;
`;
const SectionArticle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;
const SectionArticleImage = styled.img`
  max-width: 80px;
  width: 80px;
`;
const SectionArticleRight = styled.div`
  flex: 1;
  text-align: left;
  padding-left: 10px;
`;
const SectionArticleHeader = styled.p`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #707070;
  margin-bottom: 0px;
`;
const SectionArticleParagraph = styled.p`
  font-size: 12px;
  color: ${standardP};
  margin-bottom: 0px;
`;
const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 992px) {
    display: none;
  }
`;
const ImageA = styled.a`
  display: flex;
  justify-self: flex-end;
  max-width: 175px;
  > img {
    object-fit: contain;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;
const HamburgerButton = styled.button`
  background-color: transparent;
  border: 0px solid transparent;
  padding: 20px;
`;

const CloseButtonFixed = styled.button`
  border: 0px solid transparent;
  position: relative;
  align-self: flex-end;
  padding: 18px 25px;
  background-color: white;
`;
const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 12px;
`;
const MobileMenuItem = styled.button`
  background-color: ${({active}) => active ? orange : 'white'};
  border: 1px solid ${({active}) => active ? orange : 'white'};
  box-shadow: ${({active}) => active ? '3px 3px 3px 3px rgba(0,0,0,0.15);' : '0'};
  padding: 15px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({active}) => active ? 'white' : standardH3};
  font-size: 13px;
  font-weight: 600;
`;
const MobileMenuSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px;
`;

const menuItems = [
    {
        display: 'Membership',
        sections: [
            [
                {
                    id: 1,
                    type: 'header',
                    text: 'Become a Member'
                },
                {
                    id: 6,
                    type: 'link',
                    text: 'Join',
                    url: 'https://www.actox.org/membership/join.asp'
                },
                {
                    id: 7,
                    type: 'link',
                    text: 'Membership Types',
                    url: 'https://www.actox.org/membership/membership-types.asp'
                },
                {
                    id: 8,
                    type: 'link',
                    text: 'Member Benefits',
                    url: 'https://www.actox.org/membership/benefits.asp'
                },
                {
                    id: 9,
                    type: 'link',
                    text: 'Membership FAQs',
                    url: 'https://www.actox.org/membership/membership-faqs.asp'
                }
            ],
            [
                {
                    id: 12,
                    type: 'header',
                    text: 'ACT Members'
                },
                {
                    id: 13,
                    type: 'link',
                    text: 'Renew',
                    url: 'https://www.actox.org/membership/dues.asp',
                    gated: true,
                },
                {
                    id: 14,
                    type: 'link',
                    text: 'Updated Member Profile',
                    url: 'https://www.actox.org/membership/myProfile.asp',
                    gated: true,
                },
                {
                    id: 15,
                    type: 'link',
                    text: 'Password Reset',
                    url: 'https://www.actox.org/membership/reset-password.asp'
                },
                {
                    id: 16,
                    type: 'link',
                    text: 'Get Involved',
                    url: 'https://www.actox.org/membership/volunteer.asp',
                    gated: true
                }
            ],
            [
                {
                    id: 20,
                    type: 'header',
                    text: 'Corporate Membership'
                },
                {
                    id: 21,
                    type: 'link',
                    text: 'Join Us',
                    url: 'https://www.actox.org/membership/corporate-membership.asp'
                },
                {
                    id: 22,
                    type: 'link',
                    text: 'Benefits',
                    url: 'https://www.actox.org/membership/corporate-membership.asp#benefits',
                },
                {
                    id: 23,
                    type: 'link',
                    text: 'Corporate Members',
                    url: 'https://www.actox.org/membership/corporate-membership.asp#list'
                }
            ]
        ]
    },
    {
        display: 'Education',
        sections: [
            [
                {
                    id: 31,
                    type: 'header',
                    text: 'Annual Meeting',
                },
                {
                    id: 32,
                    type: 'link',
                    text: '44th Annual Meeting',
                    url: 'https://www.actox.org/am/am2023/',
                },
                {
                    id: 33,
                    type: 'link',
                    text: 'Past Annual Meetings',
                    url: 'https://www.actox.org/education/past-annual-meetings.asp',
                }
            ],
            [
                {
                    id: 38,
                    type: 'header',
                    text: 'Courses',
                },
                {
                    id: 39,
                    type: 'link',
                    text: 'Upcoming Courses',
                    url: 'https://www.actox.org/groups/education/training/courses.asp',
                },
                {
                    id: 40,
                    type: 'link',
                    text: 'Past Courses',
                    url: 'https://www.actox.org/education/past-courses.asp',
                }
            ],
            [
                {
                    id: 44,
                    type: 'header',
                    text: 'On-Demand Learning',
                },
                {
                    id: 45,
                    type: 'link',
                    text: 'eLearning Seminars',
                    url: 'https://www.actox.org/education/elearning/seminars.asp',
                },
                {
                    id: 46,
                    type: 'link',
                    text: 'Webinars',
                    url: 'https://www.actox.org/education/webinars.asp',
                },
                {
                    id: 47,
                    type: 'link',
                    text: 'ToxChats Podcasts',
                    url: 'https://www.actox.org/toxchats-podcasts.asp',
                }
            ]
        ]
    },
    {
        display: 'Journal',
        sections: [
            [
                {
                    id: 52,
                    type: 'header',
                    text: 'International Journal of Toxicology',
                },
                {
                    id: 47888,
                    type: 'link',
                    text: 'About IJT',
                    url: 'https://www.actox.org/journal/about.asp',
                },
                {
                    id: 48,
                    type: 'link',
                    text: 'Access IJT',
                    url: 'https://www.actox.org/journal/about.asp#mbr-access',
                },
                {
                    id: 49,
                    type: 'link',
                    text: 'Become a Reviewer',
                    url: 'https://www.actox.org/journal/become-a-reviewer.asp',
                },
            ],
            [
                {
                    id: 50,
                    type: 'header',
                    text: 'IJT Paper Submission',
                },
                {
                    id: 51,
                    type: 'link',
                    text: 'Submission Guidelines and Policies',
                    url: 'https://www.actox.org/journal/submission-guidelines.asp',
                },
                {
                    id: 52,
                    type: 'link',
                    text: 'Submit Your Paper',
                    url: 'https://www.actox.org/journal/submission-guidelines.asp#submit',
                },
                {
                    id: 53,
                    type: 'link',
                    text: 'Tips for Getting Published',
                    url: 'https://www.actox.org/journal/getting-published.asp',
                },
            ],
            
        ]
    },
    {
        display: 'Resources',
        sections: [
            [
                {
                    id: 1065,
                    type: 'header',
                    text: 'Public',
                },
                {
                    id: 1066,
                    type: 'link',
                    text: 'Resources for Toxicologists',
                    url: 'https://www.actox.org/resources/resources-for-toxicologists.asp',
                },
                {
                    id: 1067,
                    type: 'link',
                    text: 'Salary Survey',
                    url: 'https://www.actox.org/resources/salary-survey.asp',
                },
                {
                    id: 1068,
                    type: 'link',
                    text: 'Calendar',
                    url: 'https://interact.actox.org/events/calendar',
                },
                {
                    id: 1069,
                    type: 'link',
                    text: 'News',
                    url: 'https://interact.actox.org/home',
                },
                {
                    id: 1070,
                    type: 'link',
                    text: 'Awards and Honors',
                    url: 'https://www.actox.org/resources/awards-honors.asp',
                }
            ],
            [
                {
                    id: 65,
                    type: 'header',
                    text: 'Members Only',
                },
                {
                    id: 66,
                    type: 'link',
                    url: 'https://www.actox.org/resources/interACT.asp',
                    text: 'interACT',
                    gated: true
                },
                {
                    id: 67,
                    type: 'link',
                    url: 'https://www.actox.org/resources/directory.asp',
                    text: 'Membership Directory',
                    gated: true
                },
                {
                    id: 68,
                    type: 'link',
                    url: 'https://www.actox.org/resources/newsletter.asp',
                    text: 'Newsletter',
                    gated: true
                },
                {
                    id: 69,
                    type: 'link',
                    url: 'https://www.actox.org/resources/blog.asp',
                    text: 'ACTalks Member Blog',
                    gated: true
                },
                {
                    id: 70,
                    type: 'link',
                    url: 'https://www.actox.org/resources/career-center.asp',
                    text: 'Career Center',
                },
                {
                    id: 71,
                    type: 'link',
                    url: 'https://www.actox.org/resources/mentorship.asp',
                    text: 'Mentorship Program',
                },
                {
                    id: 72,
                    type: 'link',
                    url: 'https://www.actox.org/resources/award-history.asp',
                    text: 'Award History',
                    gated: true
                },
                {
                    id: 73,
                    type: 'link',
                    url: 'https://www.actox.org/ballot/2023-2024.asp',
                    text: 'Vote 2023-2024 Ballot',
                    gated: true
                }
            ]
        ]
    },
    {
        display: 'Endowment',
        sections: [
            [
                {
                    id: 88,
                    type: 'link',
                    url: 'https://www.actox.org/endowment/about.asp',
                    text: 'About ACT Endowment',
                },
                {
                    id: 89,
                    type: 'link',
                    url: 'https://www.actox.org/endowment/about.asp#donate',
                    text: 'Donate',
                },
                {
                    id: 90,
                    type: 'link',
                    url: 'https://www.actox.org/endowment/contributors.asp',
                    text: 'Contributors',
                }
            ]
        ]
    },
    {
        display: 'About',
        sections: [
            [
                {
                    id:  109,
                    type: 'header',
                    text: 'About ACT',
                },
                {
                    id: 110,
                    type: 'link',
                    url: 'https://www.actox.org/about/presidents-welcome.asp',
                    text: 'President\'s Welcome',
                },
                {
                    id: 111,
                    type: 'link',
                    url: 'https://www.actox.org/about/mission-overview.asp',
                    text: 'Mission and Overview',
                },
                {
                    id: 112,
                    type: 'link',
                    url: 'https://www.actox.org/dei.asp',
                    text: 'Diversity, Equity, and Inclusion',
                },
                {
                    id: 114,
                    type: 'link',
                    url: 'https://www.actox.org/about/global-outreach.asp',
                    text: 'Global Outreach',
                },
                {
                    id: 115,
                    type: 'link',
                    url: 'https://www.actox.org/about/history-of-act.asp',
                    text: 'History of ACT',
                },
                {
                    id: 116,
                    type: 'link',
                    url: 'https://www.actox.org/about/contact-us.asp',
                    text: 'Contact Us',
                },
            ],
            [
                {
                    id: 117,
                    type: 'header',
                    text: 'Governance',
                },
                {
                    id: 118,
                    type: 'link',
                    url: 'https://www.actox.org/about/council.asp',
                    text: 'Council',
                },
                {
                    id: 119,
                    type: 'link',
                    url: 'https://www.actox.org/about/committee-chart.asp',
                    text: 'Committees',
                },
                {
                    id: 120,
                    type: 'link',
                    url: 'https://www.actox.org/about/bylaws.asp',
                    text: 'Bylaws',
                },
                {
                    id: 121,
                    type: 'link',
                    url: 'https://www.actox.org/about/policy-statements.asp',
                    text: 'Policy Statements',
                },
                {
                    id: 122,
                    type: 'link',
                    url: 'https://www.actox.org/about/strategic-plan.asp',
                    text: 'Strategic Plan',
                },
            ],
        ]
    }
];

function Nav({linkPrefix}) {
    const [itemHovered, setItemHovered] = useState(null);
    const [panelHovered, setPanelHovered] = useState(null);
    const [showLoginMenu, setShowLoginMenu] = useState(false);
    const [showToXChangeMenu, setShowToXChangeMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [mobileMenuOpenIndex, setMobileMenuOpenIndex] = useState(null);
    const {
        pathname,
        search,
    } = useLocation();

    const closeAllMenus = () => {
        setShowToXChangeMenu(false);
        setShowLoginMenu(false);
    }
    const onClickNavMenu = () => {
        setTimeout(() => {
            setShowLoginMenu(!showLoginMenu);
        }, 0);
    };
    const onClickToXChangeMenu = () => {
        setTimeout(() => {
            setShowToXChangeMenu(!showToXChangeMenu);
        }, 0);
    };
    const toggleMobileMenu = () => setMobileMenu(false);
    const toggleMobileMenuOpenIndex = index => mobileMenuOpenIndex === index ?
        setMobileMenuOpenIndex(null) :
        setMobileMenuOpenIndex(index);

    useEffect(() => {
        const onWindowClick = () => {
            closeAllMenus();
        }
        window.addEventListener("click", onWindowClick);
        return () => {
            window.removeEventListener("click", onWindowClick);
        };
    }, []);

    const menuClearRef = useRef()
    const clearMenuItemHovered = useCallback(() => {
        menuClearRef.ref = debounce(() => {
            setItemHovered(null);
        }, 100)
        menuClearRef.ref();
    }, [])
    const setMenuItemHovered = index => {
        if ( index === null ) {
            clearMenuItemHovered();
        } else {
            setItemHovered(index);
            menuClearRef.ref && menuClearRef.ref.cancel();
        }
    };
    const clearPanelItemHovered = useCallback(debounce(() => {
        setPanelHovered(null);
    }, 100), [])
    const setPanelItemHovered = index => {
        if ( index === null ) {
            clearPanelItemHovered();
        } else {
            setPanelHovered(index);
        }
    };

    const renderMenuItems = (item, i) => {
        switch (item.type) {
            case 'header':
                return (
                    <SectionHeader key={item.id} first={i === 0}>{item.text}</SectionHeader>
                );
            case 'image':
                let image = (
                    <SectionImage key={item.id} src={item.url} alt={item.text}/>
                );
                if ( item.href ) {
                    return (
                        <SectionImageLink
                            href={item.href}
                            key={item.id}
                            {...(item.target ? {target: item.target} : {})}
                        >
                            {image}
                        </SectionImageLink>
                    );
                } else {
                    return image;
                }
            case 'paragraph':
                return (
                    <SectionText key={item.id}>{item.text}</SectionText>
                );
            case 'button':
                return (
                    <SectionButton
                        key={item.id}
                        href={item.url}
                        {...(item.target ? {target: item.target} : {})}
                        actionButton={item.actionButton}
                    >{item.text}</SectionButton>
                );
            case 'link':
                return (
                    <SectionLink
                        key={item.id}
                        href={item.url}
                        {...(item.target ? {target: item.target} : {})}
                    >
                        {item.text}
                        &nbsp;&nbsp;
                        {
                            item.gated ? <FontAwesomeIcon icon={faLock}/> : null
                        }
                    </SectionLink>
                );
            case 'article':
                return (
                    <SectionArticle key={item.id} href={item.url} target="_blank">
                        <SectionArticleImage src={item.imageUrl} alt={item.imageText}/>
                        <SectionArticleRight>
                            <SectionArticleHeader>{item.headerText}</SectionArticleHeader>
                            <SectionArticleParagraph>{item.paragraphText}</SectionArticleParagraph>
                        </SectionArticleRight>
                    </SectionArticle>
                );
        }
    };

    const panelToDisplay = itemHovered !== null ? itemHovered : panelHovered;

    return (
        <StyledNav>
            <Modal
                isOpen={mobileMenu}
                onRequestClose={toggleMobileMenu}
                contentLabel="Menu Modal"
                style={{
                    content: {
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                    },
                    overlay: {
                        zIndex: 100,
                    }
                }}
            >
                <CloseButtonFixed onClick={toggleMobileMenu}>
                    <FontAwesomeIcon icon={faClose} className=""/>
                </CloseButtonFixed>
                <MobileMenuWrapper>
                    {
                        menuItems.map((menuItem, index) => (
                            <React.Fragment>
                                <MobileMenuItem onClick={() => toggleMobileMenuOpenIndex(index)} active={index === mobileMenuOpenIndex}>
                                    <span>{menuItem.display}</span>
                                    <FontAwesomeIcon icon={faCaretDown}/>
                                </MobileMenuItem>
                                {
                                    mobileMenuOpenIndex === index ? (
                                        <MobileMenuSection>
                                            {(menuItem.sections || []).map(section => (section || []).map(renderMenuItems))}
                                        </MobileMenuSection>
                                    ) : null
                                }
                            </React.Fragment>
                        ))
                    }
                </MobileMenuWrapper>
            </Modal>

            <MobileContainer>
                <ImageA href={`${linkPrefix}/`}>
                    <img src="/images/SOT_logo_Tag_OL.png" alt="SOT Society of Toxicology logo"/>
                </ImageA>
                <HamburgerButton aria-label="Open Menu Button" onClick={() => setMobileMenu(true)}>
                    <FontAwesomeIcon icon={faBars}/>
                </HamburgerButton>
            </MobileContainer>
            <Container className="container">
                {
                    menuItems.map((menuItem, index) => (
                        <Item key={menuItem.display}>
                            <ItemButton
                                hovered={index === itemHovered || index === panelHovered}
                                onMouseEnter={() => setMenuItemHovered(index)}
                                onMouseLeave={() => setMenuItemHovered(null)}
                            >
                                {menuItem.display}
                                &nbsp;
                                <FontAwesomeIcon size="xs" icon={faChevronDown} className=""/>
                            </ItemButton>
                        </Item>
                    ))
                }
            </Container>
            <PanelWrapper>
                <Panel
                    displayPanel={panelToDisplay !== null}
                    onMouseEnter={() => setPanelItemHovered(panelToDisplay)}
                    onMouseLeave={() => setPanelItemHovered(null)}
                >
                    <InnerPanel>
                        {
                            menuItems[panelToDisplay] && menuItems[panelToDisplay].sections.map((section, index) => {
                                return (
                                    <PanelSection key={section[0] && section[0].id} first={index === 0}>
                                        {
                                            section.map(renderMenuItems)
                                        }
                                    </PanelSection>
                                );
                            })
                        }
                    </InnerPanel>
                </Panel>
            </PanelWrapper>
        </StyledNav>
    );
}

export default Nav;
