import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
  faVimeo,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faPodcast,
  faLocationDot,
  faPhone,
  faEnvelope,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons'
import { blue, red } from "../styles";

const StyledFooter = styled.footer`
  text-align: center;
  color: #626262;
  background-color: #f7f7f7;
  text-align: left;
  padding-top: 60px;
  padding-bottom: 0px;
  margin-top: 50px;
  a {
    color: #363636;
    :hover {
      color: #84ba3f;
    }
  }
`;
const FooterContainer = styled.div`
  max-width: 1170px;
`;
const SocialList = styled.ul` 
  > li {
    display: inline-block;
    text-align: center;
  }
`;
const ContactInfoList = styled.ul`
  > li {
    display: block;
    margin: 0;
    padding: 0 10px 5px 0;
    position: relative;
  }
`;
const FooterList = styled.ul`
  margin-bottom: 40px;
  padding: 0;
  > li {
    display: block;
    margin: 0;
    padding: 0 10px 10px 0px;
    position: relative;
  }
`;
const Logo = styled.img`
  margin-bottom: 30px;
`;
const SocialLink = styled.a`
  display: block;
  background-color: ${red};
  border-radius: 50%;
  height: 25px;
  line-height: 25px;
  margin: 2px 7px 0px 0;
  width: 25px;
`;
const Heading = styled.h4`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.42857143;
  font-weight: 500;
`;
const FooterBottom = styled.div`
  background-color: #1a1a1a;
  padding: 30px 0;
`;
const FooterBottomLeft = styled.div`
  text-align: center;
  margin-left: 0;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
  @media screen and (min-width: 992px) {
    text-align: left;
  }
`
const FooterBottomRight = styled.div`
  border: 0;
  margin: 0;
  @media screen and (min-width: 992px) {
    text-align: left;
  }
`
const BottomMenu = styled.ul`
  font-size: 14px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: right;
  > li {
    display: inline-block;
    margin-right: 15px;
    > a {
      color: #84ba3f;
    }
  }
`
const ColorGreen = styled.a`
  color: #84ba3f !important;
`;
const SectionHeader = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #003087;
  text-transform: uppercase !important;
  margin-bottom: 30px;
`;

const FooterLine = styled.div`
  width: 85%;
  border-right: solid thin lightgray;
  height: 222px
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: #84ba3f;
  display: inline-block;
  position: absolute;
  top: 5px;
`;
const TextWithIcon = styled.div`
  margin-left: 30px;
`;
const RightSideImage = styled.img`
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
  max-width: 390px;
`;
const RightSideText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #626262;
`;

const currentYear = new Date().getFullYear();

function Footer({ linkPrefix }) {
  return (
    <StyledFooter id="footer" aria-label="footer section">
      <FooterContainer className="container no-break" style={{paddingBottom: '60px'}}>
        <div className="row">
          <div className="col-lg-2 col-sm-3 sm-mb-30">
            <div>
              <SectionHeader className='mb-30 mt-10 text-uppercase'>Quick Links</SectionHeader>
              <FooterList className="mb-40">
                <li><a href="/index.asp">Home</a></li>
                <li><a href="/membership/join.asp">Join</a></li>
                <li><a href="https://interact.actox.org/volunteeropportunities/opportunities-list-public">Volunteer</a></li>
                <li><a href="/journal/about.asp">Journal</a></li>
              </FooterList>
            </div>
          </div>
          <div className="col-lg-3 col-md-5 col-sm-6 xs-mb-30">
            <div>
              <SectionHeader className='mb-30 mt-10 text-uppercase'>Contact Us</SectionHeader>
              <div className="widget dark">
                <ContactInfoList className="list-inline">
                  <li style={{ marginBottom: '12px' }}> <StyledIcon icon={faLocationDot} /><TextWithIcon>11190 Sunrise Valley Dr., Suite 300, Reston, VA 20191</TextWithIcon> </li>
                  <li> <StyledIcon icon={faPhone} /> <TextWithIcon><a href="tel:+17034383115">703.438.3115</a></TextWithIcon> </li>
                  <li> <StyledIcon icon={faEnvelope} /> <TextWithIcon><a id="footer-email" href="mailto:acthq@actox.org">acthq@actox.org</a></TextWithIcon> </li>
                </ContactInfoList>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-3 xs-mb-30">
            <FooterLine>
              <SectionHeader className='mb-30 mt-10 text-uppercase'>Let's Connect</SectionHeader>
              <FooterList className="mb-40">
                <li style={{ marginBottom: '12px' }}><a href='https://twitter.com/actoxicology?lang=en'>
                  <StyledIcon icon={faTwitter} /><TextWithIcon>Twitter</TextWithIcon></a></li>
                <li style={{ marginBottom: '12px' }}><a href='https://www.linkedin.com/company/act-american-college-of-toxicology/'>
                  <StyledIcon icon={faLinkedin} /><TextWithIcon>LinkedIn</TextWithIcon></a></li>
              </FooterList>
            </FooterLine>
          </div>
          <div className='col-lg-4 col-sm-12'>
            <RightSideImage src={`${linkPrefix}/images/web-logo-footer.png`} />
            <RightSideText>The American College of Toxicology is a global community of industry, government, and academic professionals renowned for its ability to educate, lead, and serve scientists related to applied toxicology in a welcoming and collegial environment.</RightSideText>
          </div>
        </div>
      </FooterContainer>
      <FooterBottom>
        <FooterContainer className="container">
          <div className="row">
            <div className="col-md-6 text-right">
              <FooterBottomRight>
                <BottomMenu>
                  <li className="footer-privacy"> <a href={`${linkPrefix}/about/privacy-policy-and-disclaimers.asp`} className="underline">Privacy Policy</a> </li>
                  <li >|</li>
                  <li className="footer-faq"> <a href={`${linkPrefix}/about/code-of-conduct.asp`} className="underline" >Code of Conduct</a> </li>
                  <li >|</li>
                  <li className="footer-faq"> <a href="mailto:acthq@actox.org?subject=Unsubscribe%20Request" className="underline" >Unsubscribe</a> </li>
                </BottomMenu>
              </FooterBottomRight>
            </div>
            <div className="col-md-6">
              <FooterBottomLeft>Copyright &copy;
                {currentYear}&nbsp;
                <ColorGreen>American College of Toxicology</ColorGreen> All rights reserved.</FooterBottomLeft>
            </div>
          </div>
        </FooterContainer>
      </FooterBottom>
    </StyledFooter>
  );
}

export default Footer;
