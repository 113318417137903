import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Login from './components/Login';
import Admin from './components/Admin';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import {coursePath} from "./constants";
import Materials from "./components/materials/Materials";
import Materials2 from "./components/materials/Materials2";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "admin",
                element: <Admin />,
            },
            {
                path: "materials",
                element: <Materials/>,
            },
            {
                path: "materials2",
                element: <Materials2/>,
            },
            {
                path: "*",
                element: <Navigate to="/" replace/>,
            }
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
