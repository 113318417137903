import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import {blue, orange, red} from "../styles";
import {coursePath} from "../constants";
import {getCourse} from "../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {processAction} from "../services/loginAction";


const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const H2 = styled.h2`
  margin-top: 40px;
  color: ${blue};
  font-weight: 600;
  line-height: 1.42857143;
`
const Button = styled.a`
  background-color: ${orange};
  border-color: ${orange};
  margin-bottom: 40px;
`;
const P = styled.p`
  margin-bottom: 40px;
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const Page = styled.div`
    text-align: left;
`;
const LoginButton = styled.button`
  color: white;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid ${red};
  border-radius: 0.375rem;
  background-color: ${red};
  opacity: 0.9;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    opacity: 0.8;
  }
`;

function Login({}) {
    const {
        search,
    } = useLocation();
    const [loading, setLoading] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [title, setTitle] = useState('');
    const [oldSiteKey, setOldSiteKey] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    let query = new URLSearchParams(search)
    let redirect = query.get('redirect');
    let action = query.get('action');

    const submit = async () => {
        try {
            setError('');
            let result = await fetch(`/api/login`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    password,
                })
            });
            let { errorMessage } = await result.json();
            if ( errorMessage || result.status === 401) {
                setError('Invalid email or password');
            } else {
                if ( action ) {
                    setLoading(true);
                    let actionResult = await processAction(action, navigate);
                    if ( actionResult.message ) {
                        setError( actionResult.message );
                    }
                    setLoading(false);
                    if ( actionResult.processed ) {
                        if ( actionResult.downloadUrl ) {
                            setDownloadUrl( actionResult.downloadUrl );
                        }
                        return;
                    }
                }
                let url = redirect && redirect.startsWith('/') ? redirect : '/';
                navigate(url, {replace: true});
                navigate(0);
            }
        } catch (e) {
            setError('An error occurred when attempting to login');
        }
    };
    return (
        <Page>
            {
                loading ? (
                    <Panel>
                        <Loading>
                            <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                        </Loading>
                    </Panel>
                ) : downloadUrl ? (
                    <Panel>
                        <div>
                            If your download has not triggered automatically, please click here: <a href={downloadUrl} target="_blank">Download</a>
                        </div>
                    </Panel>
                ) : (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-xs-12 col-md-4 mb-40">
                                <div>
                                    <p>The page you are requesting requires a login. Please provide your login details below.</p>
                                    <div className="form-group">
                                        <label htmlFor="ACTlogin">Email*</label>
                                        <input id="ACTlogin" name="ACTlogin" className="form-control" type="text"
                                               value={email}
                                               onChange={e => setEmail(e.target.value)}
                                               autoComplete="username"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="ACTpw">Password*</label>
                                        <input id="ACTpw" name="ACTpw" className="form-control" type="password"
                                               value={password}
                                               onChange={e => setPassword(e.target.value)}
                                               autoComplete="current-password"/>
                                    </div>

                                    <br/>
                                    <div className="form-group  mt-10">
                                        <LoginButton id="login-submit" type="submit" onClick={submit}
                                                     disabled={!password || !email}
                                                     className="mr-10 mb-xs-20">Login
                                        </LoginButton>
                                    </div>
                                    {
                                        error ? (
                                            <React.Fragment>
                                                <br/>
                                                <div className="alert alert-warning" role="alert">
                                                    {error}
                                                </div>
                                            </React.Fragment>
                                        ) : null
                                    }
                                    <div className="clear text-left">
                                        <p><a id="reset-password" className="text-theme-colored"
                                              href="https://www.aim-hq.com/netFORUMact/eweb/DynamicPage.aspx?webcode=ForgotPassword&amp;Site=ACT"
                                              data-feathr-click-track="true"
                                              data-feathr-link-aids="[&quot;5f3d5ab794d4456c741321a7&quot;]">Reset
                                            Password</a></p>
                                    </div>
                                    <br/>
                                </div>

                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        </Page>
    );
}

export default Login;
