import styled from "styled-components";

export const orange = '#84BA3F';
export const blue = '#004683';
export const darkBlue = '#003766';
export const gray = '#6D6E71';
export const red = '#84BA3F';
export const standardP = '#3c3b3b';
export const standardH3 = '#333333';
export const grayBoxes = '#f7f7f7';


export const PrinterBreak = styled.div`
  @media print {
    page-break-after: always;
  }
`
